import React from 'react';
import getExtensionFromUrl from '../../../utils/getExtensionFromUrl';
import { HeroImageStyle } from '../styles/HeroNewStyles';

const HeroImage = ({ component }) => {
  const isAnimated = getExtensionFromUrl(component?.image?.url) === 'json';
  if (isAnimated) return null;
  const imageProps = {
    alt: 'Hero Image',
    height: component?.image?.height,
    image: component?.image?.gatsbyImageData,
    loading: 'eager',
    msrc: component?.imageMobile?.url,
    src: component?.image?.url,
    width: component?.image?.width,
  };

  return <HeroImageStyle {...imageProps} />;
};

export default HeroImage;
